import http from "@/_helper/api-services";


export default {
    data() {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const minDate = new Date(today);

        minDate.setMonth(minDate.getMonth() - 1);

        return {
            nomeLogin: '',
            x_auth_token: "05966310d341687c692f0e8bcaaf8f93",
            emails: [],
            status: ["all", "delivered", "errors"],
            status_selected: "all",
            start_date: '2021-05-01',
            end_date: '2021-05-04',
            page: 1,
            per: 100
        };
    },
    components: {
    },
    mounted() {
        this.getEmailsLocaweb(false);
        //this.obterNomeLogado();
        this.$loading(false);
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        async getEmailsLocaweb() {
            this.$loading(true);

            await http.get(`/Admin/ListEmailsLocaweb?status=${this.status_selected}&start_date=${this.start_date}&end_date=${this.end_date}&page=${this.page}&per=${this.per}`).then(
                success => {
                    //this.$snotify.success("Pagamento aprovado com sucesso");
                    this.emails = success.data.data.messages;
                },
                error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                    // else this.$snotify.error("An unexpected error has occurred");
                }
            );

            this.$loading(false);
        },
    },
};